<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card class="p-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa fa-solid fa-graduation-cap " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
              <span class="btn_in_header">
                <b-button variant="primary" class="m-1" @click="goToSessionDashboard">{{cvbtnAddNew}}</b-button>
                <b-button variant="primary" class="m-1" @click="goToSubjectModuleConfigs">{{cvbtnSubjectModuleConfig}}</b-button>
                <b-button variant="primary" class="m-1" @click="appAdd()">{{cvbtnAppAdd}}</b-button>
              </span>
            </h4>
          </template>
          <!-- App Edit Modal -->
          <b-modal
            v-model="showModelQuizEdit"
            scrollable
            :title="cvEditModalHeader"
            size="xl"
          >
            <AppEdit :propOpenedInModal="true" :propQuizObj="quizEditObj" @emitCloseQuizEditModal="closeQuizEditModal" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" @click="appEdit('FULLPAGE')">
                {{cvbtnModalExpand}}
              </b-button>
              <b-button size="sm" @click="closeQuizEditModal()">
                {{cvbtnModalCancel}}
              </b-button>
            </template>
          </b-modal><!-- App Edit Modal -->

          <!-- User Id Input Modal for attempting the quiz -->
          <b-modal
            v-model="showAttemptingUserIdModal"
            scrollable
            :title="cvAttemptingUserIdModalTitle"
            size="lg"
          >
            <div class="col-md-12 mb-3">
              <label for="validationslot_duration">{{ cvAttemptingUserIdLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
              <input v-model="attemptingUserId" type="text" class="form-control" required/>
            </div>

            <button type="button" class="btn btn-primary ml-1" @click="attemptQuizForTesting()">{{ "Attempt Quiz" }}</button>

            <template #modal-footer="">
              <b-button size="sm" @click="emitCloseAttemptingUserIdModal()">
                {{cvbtnModalCancel}}
              </b-button>
            </template>
          </b-modal><!-- User Id Input Modal for attempting the quiz -->

          <!-- App Add Modal -->
          <b-modal
            v-model="showModelQuizAdd"
            scrollable
            :title="cvAddAppModalHeader"
            size="xl"
          >
            <AppAdd :propOpenedInModal="true" @emitCloseQuizAddModal="closeQuizAddModal" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" @click="appAdd('FULLPAGE')">
                {{cvbtnModalExpand}}
              </b-button>
              <b-button size="sm" @click="closeQuizAddModal()">
                {{cvbtnModalCancel}}
              </b-button>
            </template>
          </b-modal><!-- App Add Modal -->

          <!-- App Delete Dialog box -->
          <b-modal v-model="showModelQuizDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
            <p>{{cvDelMessage}}</p>
            <template #modal-footer="">
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button size="sm" @click="showModelQuizDelete = false">
                {{cvbtnModalCancel}}
              </b-button>
              <b-button size="sm" variant="primary" @click="appDelete()">
                {{cvbtnModalDelOk}}
              </b-button>
            </template>
          </b-modal><!-- App Delete Dialog box -->

          <template v-slot:body>
            <!-- Quiz count box -->
            <!-- Disabling the Quiz Count box as of now -->
            <!-- <template>
              <div class="row mb-3" v-if="quiz_count">
                <div class="col-12 col-xs-6 col-sm-4 col-md-3 col-lg-3">
                  <div class="card text-white bg-danger mb-2" >
                    <div class="card-body">
                      <h4 class="card-title text-white" style="text-align:center">
                        <div>
                          Quiz Count:&nbsp; {{quiz_count}}
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </template>Quiz count box -->

            <div :key="refreshTheFilterBadges" class="m-2">
              <b-badge v-for="(subjectModuleListRAnalyticsObj, index) of tprepQuizListAnalyticsObjList" :key="index+subjectModuleListRAnalyticsObj.interest_id" title="Show active questions only" :style="setTagStyles(subjectModuleListRAnalyticsObj.interest_id)" class="tags mr-2 mb-2 font-weight-normal" variant="none" @click="filterTestSeriesModules(subjectModuleListRAnalyticsObj.interest_id)">
                <span>
                  {{ subjectModuleListRAnalyticsObj.subject_name ? subjectModuleListRAnalyticsObj.subject_name : "UNTAGGED_APPS" }}:
                  &nbsp; {{ subjectModuleListRAnalyticsObj.module_count ? subjectModuleListRAnalyticsObj.module_count : (subjectModuleListRAnalyticsObj.untagged_apps > 0 ? subjectModuleListRAnalyticsObj.untagged_apps : 0) }}
               </span>
              </b-badge>
            </div>

            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchtprep_quizWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->

              <!-- Exam Name Filter -->
              <!-- Disabling the Quiz Count box as of now -->
              <!-- <b-col class="col-4">
                <select v-model="whereFilter.exam_name" class="form-control" @change="tprep_quizList">
                  <option value="ALL"><span style="color:#e5252c;">Exam Name.</span></option>
                  <option v-for="(exam, index) of exam_name_list" :key="index+exam" :value="exam">{{exam}}</option>
                </select>
              </b-col>Exam Name Filter -->

              <!-- Quiz Batch Number Filter -->
              <b-col class="col-4">
                <select v-model="whereFilter.batch_no" class="form-control" @change="tprep_quizList">
                  <option value="ALL"><span style="color:#e5252c;">Batch No.</span></option>
                  <option v-for="(batch, index) of distinct_batch_no" :key="index" :value="batch">{{batch}}</option>
                </select>
              </b-col><!-- Quiz Batch Number Filter -->
            </b-row>

            <b-row>
              <b-col class="container_minheight" md="12" v-if="tprep_quizObjList && tprep_quizObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="tprep_quizObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(app_category)="data">
                    <p>{{data.item.app_category}}</p>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>
                  </template>
                  <template v-slot:cell(batch_no)="data">
                   <b style="color:#e5252c;">{{data.item.batch_no}}</b>
                  </template>
                  <template v-slot:cell(app_name)="data">
                    {{data.item.app_name}}<br>
                    <b-button variant="mr-1 mb-1 " @click="openApp(data.item)" title="View App"><i class="fa fa-eye font-size-20" aria-hidden="true" ></i></b-button>
                    <!-- <b-button variant=" mr-1 mb-1 " @click="syncResult(data.item)" title="Sync Result"><i class="fa fa-refresh font-size-20" aria-hidden="true"></i></b-button> -->
                    <b-button variant=" mr-1 mb-1 " @click="quizBankList(data.item)" title="Question Bank" v-if="!data.item.editable"><i class="fa-solid fa-file-circle-question font-size-20"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="appResultList(data.item)" title="Results"><i class="fa fa-star font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="appEdit('MODAL', data.item)" title="App Edit" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0 font-size-20" title="Edit"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="showQuizDeleteDialog(data.item)" title="Delete"><i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i></b-button>
                    <!-- sync button here -->
                    <b-button variant=" mr-1 mb-1 " @click="solveQuizBankViaGPT(data.item)" title="Solve Quiz"><i class="fa fa-quora font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " v-if="userData.user_role == 'USERROLE11111'" @click="openUserIdInputModel(data.item)" title="Attempt Quiz For Testing"><i class="fa fa-flask font-size-20" aria-hidden="true"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import { TprepQuiz } from "../../../FackApi/api/TprepQuiz.js"
import AppEdit from "../Apps/AppEdit.vue"
import { Apps } from "../../../FackApi/api/apps"
import AppAdd from "../Apps/AppAdd.vue"
import moment from "moment"
import { QuizBanks } from "../../../FackApi/api/quizBank"
import userPermission from "../../../Utils/user_permission.js"
import GideProductsJson from "../../../FackApi/json/GideProducts.json"
import TestSeriesConfigJson from "../../../FackApi/json/TestSeries.json"

export default {
  name: "TprepQuizDashboard",
  components: {
    AppEdit,
    AppAdd
  },
  data () {
    return {
      GideProductsJson: GideProductsJson,
      TestSeriesConfigJson: TestSeriesConfigJson,
      apiName: "app_list_console",
      cvCardTitle: "TestSeries Quiz Dashboard",
      cvbtnAddNew: "Testseries Dashboard",
      cvbtnAppAdd: "Add (+)",
      cvbtnSubjectModuleConfig: "Testseries Config",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit TestSeries Quiz",
      cvAddModalHeader: "Add TestSeries Quiz",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TestSeries Quiz",
      showModelTprepQuizAdd: false,
      showModelTprepQuizEdit: false,
      showModelTprepQuizDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "app_name", class: "text-left align-text-top", sortable: true },
        { label: "Category", key: "app_category", class: "text-left align-text-top", sortable: true },
        { label: "Duration", key: "app_duration", class: "text-left align-text-top", sortable: true },
        { label: "Total Questions", key: "app_total_questions", class: "text-left align-text-top", sortable: true },
        { label: "Batch No.", key: "batch_no", class: "text-left align-text-top", sortable: true },
        { label: "Interest Id ", key: "interest_id", class: "text-left align-text-top", sortable: true }
      ],
      tprep_quizObjList: [],
      tprep_sessionEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      delObj: null,
      whereFilter: {
        interest_id: "ALL",
        batch_no: "ALL"
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 200,
      showLoadMoreBtn: true,
      quizEditObj: null,
      showModelQuizEdit: false,
      showModelQuizDelete: false,
      distinct_batch_no: [],
      quiz_count: null,
      interest_id_list: ["SAT", "CUET"],
      showModelQuizAdd: false,
      cvAddAppModalHeader: "Add Quiz",
      showAttemptingUserIdModal: false,
      cvAttemptingUserIdModalTitle: "Enter the user_id of the user for which the quiz has to be attempted",
      attemptingUserId: null,
      cvAttemptingUserIdLabel: "User Id",
      attemptQuizAppId: null,
      tprepQuizListAnalyticsObjList: [],
      refreshTheFilterBadges: 0,
      subjectModuleFilterMap: {} // indicated currently active filters
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.query.batch_no) {
      this.whereFilter.batch_no = this.$route.query.batch_no
    }
    if (this.$route.query.interest_id) {
      this.whereFilter.interest_id = this.$route.query.interest_id
    }
    this.tprep_quizList()
    this.tprep_quizList_analytics()
  },
  methods: {
    /**
     * searchtprep_quizWithParams
     */
    async searchtprep_quizWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.tprep_quizList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.tprep_quizList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async gettprep_quizDateWise () {
      this.tprep_quizList()
      await this.setDateFilter()
    },
    /**
     * openUserIdInputModel
     */
    openUserIdInputModel (appDetails) {
      // Open the modal to input the user_id of the user whose attempt needs to be generated
      this.attemptQuizAppId = appDetails.app_id
      this.showAttemptingUserIdModal = true
    },
    /**
     * attemptQuizForTesting
     */
    async attemptQuizForTesting () {
      try {
        const attempQuizObj = {
          user_id: this.userData.user_id,
          gide_user_id: this.attemptingUserId,
          app_id: this.attemptQuizAppId
        }
        const attemptQuizResp = await QuizBanks.appsAutomaticResultGenerate(this, attempQuizObj)
        if (attemptQuizResp.resp_status) {
          this.showAttemptingUserIdModal = false
        }
        ApiResponse.responseMessageDisplay(this, attemptQuizResp)
      }
      catch (err) {
        console.error("Exception in attemptQuizForTesting and err: ", err)
      }
    },
    /**
     * tprep_quizList
     */
    async tprep_quizList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.quiz_type = "testseries"
        this.whereFilter.event_state = 99 // Needed for deleted filter

        let tprepQuizListResp = await TprepQuiz.tprep_quizList(this, this.whereFilter)
        if (tprepQuizListResp.resp_status) {
          if ((this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) || this.whereFilter.batch_no != "ALL" || this.whereFilter.interest_id != "ALL") {
            this.tprep_quizObjList = [ ...tprepQuizListResp.resp_data.data ]
          }
          else {
            this.tprep_quizObjList = [ ...this.tprep_quizObjList, ...tprepQuizListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.tprep_quizObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.tprep_quizObjList.length

          this.quiz_count = this.tprep_quizObjList.length
          if (!this.whereFilter.batch_no) {
            this.whereFilter.batch_no = "ALL"
          }

          // set the batch no. for appropriate exam
          if (tprepQuizListResp.distinct_batch_no) {
            let examBatchInfo = tprepQuizListResp.distinct_batch_no.find(e => e.interest_id.includes(this.whereFilter.interest_id))
            if (examBatchInfo) {
              this.distinct_batch_no = new Array((examBatchInfo.max_batch_no - examBatchInfo.min_batch_no) + 1).fill(0).map((_, index) => index + 1)
            }
            else {
              this.distinct_batch_no = []
            }
          }
          else {
            this.distinct_batch_no = []
          }
        }
        else {
          this.distinct_batch_no = []
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = tprepQuizListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_quizList() and Exception:", err)
      }
    },
    /**
     * setTagStyles
     */
    setTagStyles (interestId) {
      // Untagged apps have no interest_id
      if (!interestId) {
        let styles = "cursor:pointer;font-size:1rem;"
        if (this.subjectModuleFilterMap["UNTAGGED_APPS"] == 1) {
          styles += `background-color:${TestSeriesConfigJson.TESTSERIES_UNTAGGED_APPS};color:#fff;`
        }
        else {
          styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_UNTAGGED_APPS};color:${TestSeriesConfigJson.TESTSERIES_UNTAGGED_APPS};`
        }
        return styles
      }

      let styles = "cursor:pointer;font-size:1rem;"
      if (interestId.toUpperCase().includes("ENG") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("ENG")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("MATH") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("MATH")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};`
      }
      // We can add multiple subjects here as we extend the exsams we support on our platform

      if (interestId.toUpperCase().includes("MOCK") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("MOCK")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("BIO") && this.subjectModuleFilterMap[interestId] == 1) {
        // Filter is selected so set a solid background with white text
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_BIO_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("BIO")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_BIO_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_BIO_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("CHEMISTRY") && this.subjectModuleFilterMap[interestId] == 1) {
        // Filter is selected so set a solid background with white text
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_CHEMISTRY_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("CHEMISTRY")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_CHEMISTRY_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_CHEMISTRY_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("PHYSICS") && this.subjectModuleFilterMap[interestId] == 1) {
        // Filter is selected so set a solid background with white text
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_PHYSICS_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("PHYSICS")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_PHYSICS_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_PHYSICS_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("CLAT") && this.subjectModuleFilterMap[interestId] == 1) {
        // Filter is selected so set a solid background with white text
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_CLAT_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("CLAT")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_CLAT_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_CLAT_BADGE_COLOR};`
      }

      return styles
    },
    /**
     * tprep_quizList_analytics
     */
    async tprep_quizList_analytics () {
      try {
        let tprepQuizListAnalyticsResp = await TprepQuiz.tprep_quizList_analytics(this, { quiz_type: GideProductsJson.TESTSERIES })
        if (!tprepQuizListAnalyticsResp.resp_status) {
          this.toastMsg = "Could not load Analytics"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.tprepQuizListAnalyticsObjList = tprepQuizListAnalyticsResp.resp_data.data

        // setting the filter map for various subject module types (e.g. TESTSERIES_SAT_PRACTICE_ENG, TESTSERIES_SAT_PRACTICE_MATH, TESTSERIES_SAT_MOCK)
        this.tprepQuizListAnalyticsObjList.forEach((e) => {
          if (e.interest_id) {
            this.subjectModuleFilterMap[e.interest_id] = 0
          }
          else {
            this.subjectModuleFilterMap["UNTAGGED_APPS"] = 0
          }
        })
      }
      catch (err) {
        console.error("Exception occurred at tprep_quizList_analytics() and Exception:", err)
      }
    },
    /**
     * goToSessionDashboard
     */
    goToSessionDashboard () {
      this.$router.push("/testseries_admin_dashboard")
    },
    /**
     * goToSubjectModuleConfigs
     */
    goToSubjectModuleConfigs () {
      this.$router.push("/testseries_configuration")
    },
    /**
     * openApp
     */
    openApp (app) {
      this.$router.push(`/quiz_detail/${app.app_id}`)
    },
    /**
     * quizBankList
     */
    async quizBankList (exam) {
      this.$router.push(`/quizBank_list/${exam.app_id}`)
    },
    /**
     * appResultList
     */
    appResultList (app) {
      this.$router.push(`/appResult_list/${app.app_id}`)
    },
    /**
     * appEdit
     */
    appEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/quiz_edit/" + this.quizEditObj.app_id)
        }
        else {
          this.quizEditObj = item
          this.showModelQuizEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at appEdit() and Exception:", err.message)
      }
    },
    /**
     * appAdd
     */
    appAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/app_add")
        }
        else {
          this.showModelQuizAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at appAdd() and Exception:", err.message)
      }
    },
    /**
     * closeQuizEditModal
     */
    closeQuizEditModal () {
      try {
        this.showModelQuizEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeQuizEditModal() and Exception:", err.message)
      }
    },
    /**
     * emitCloseAttemptingUserIdModal
     */
    emitCloseAttemptingUserIdModal () {
      this.showAttemptingUserIdModal = false
    },
    /**
     * showQuizDeleteDialog
     */
    showQuizDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelQuizDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showQuizDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * appDelete
     */
    async appDelete () {
      try {
        let quizDelResp = await Apps.appDelete(this, this.delObj.app_id)
        await ApiResponse.responseMessageDisplay(this, quizDelResp)

        if (quizDelResp && !quizDelResp) {
          this.showModelQuizDelete = false
          return false
        }

        let index = this.tprep_quizObjList.indexOf(this.delObj)
        this.tprep_quizObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelQuizDelete = false
      }
      catch (err) {
        console.error("Exception occurred at appDelete() and Exception:", err.message)
      }
    },
    /**
     * closeQuizAddModal
     */
    closeQuizAddModal (quizAddData) {
      try {
        if (quizAddData) {
          if (this.tprep_quizObjList && this.tprep_quizObjList.length >= 1) {
            let appObjLength = this.tprep_quizObjList.length
            let lastId = this.tprep_quizObjList[appObjLength - 1]["id"]
            quizAddData.id = lastId + 1
          }
          else {
            this.tprep_quizObjList = []
            quizAddData.id = 11111
          }

          quizAddData.created_on = moment()
          this.tprep_quizObjList.unshift(quizAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelQuizAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closequizAddModal() and Exception:", err.message)
      }
    },
    /**
     * solveQuizBankViaGPT
     */
    async solveQuizBankViaGPT (appData) {
      try {
        const solveObj = {
          app_id: appData.app_id,
          app_category: appData.app_category,
          app_type: appData.app_type,
          app_total_questions: appData.app_total_questions
        }
        let quizSolveResp = await QuizBanks.quizBankSolve(this, solveObj)
        if (!quizSolveResp.resp_status) {
          this.toastMsg = quizSolveResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.toastMsg = quizSolveResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
      }
      catch (err) {
        console.error("Exception in solveQuizBankViaGPT and err: ", err)
      }
    },
    /**
     * filterTestSeriesModules
     */
    filterTestSeriesModules (interestId) {
      this.tprepQuizListAnalyticsObjList.forEach((e) => {
        if (!e.interest_id) {
          this.subjectModuleFilterMap["UNTAGGED_APPS"] = 0
        }
        else {
          this.subjectModuleFilterMap[e.interest_id] = 0 // unset all the filters
        }
      })

      if (!interestId) {
        interestId = "UNTAGGED_APPS"
      }
      this.subjectModuleFilterMap[interestId] = 1
      this.refreshTheFilterBadges += 1
      this.whereFilter.interest_id = interestId
      this.tprep_quizList()
    }
  }
}
</script>
<style scoped>
@media (max-width: 1000px){
  span.btn_in_header {
    float: right !important;
    position: unset !important;
  }
}
</style>
